import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

const PlanoList = Loadable(lazy(() => import('./PlanoList')));
const PlanoForm = Loadable(lazy(() => import('./PlanoForm')));

const planosRoutes = [
  { path: '/planos', element: <PlanoList /> },
  { path: '/plano', element: <PlanoForm /> },
  { path: '/plano/:id', element: <PlanoForm /> },
];

export default planosRoutes;
